@import '@common/LightList.less';
// @import './LessonPopup.less';
@lesson-bg-color: rgba(32, 34, 36, 0.58);
@common-bg-color: rgba(20, 23, 25, 0.7);

@common-part-list-item-width: 334px;

.part-container (@level-header-height) {
	.part-container {
		animation: fadein 1.2s;
		height: 100%;
		width: @common-part-list-item-width;
		// width: 100%;
		margin: 0 auto;
		overflow: auto;
		max-width: 1000px;
		padding-top: 10px;
		transition-duration: 0.6s;
		.part-accordion {
			border-top: 0;

			&::before {
				display: none;
			}
			.part-panel {
				.grid-list();
				.part-header-num {
					display: flex;
					width: 25px;
					height: 25px;
					line-height: 25px;
					align-items: center;
					justify-content: center;
					align-items: center;
					float: left;
					border: 2px solid #fff;
					border-radius: 50%;
					margin-right: 5px;
					font-weight: normal;
				}
				.lesson-item {
					position: relative;
					background: @lesson-bg-color;
					border: 1.5px solid rgba(103, 113, 116, 0.3);
					margin-bottom: 14px;
					border-radius: 10px;
					padding: 24px 0;
					display: flex;
					height: 118px;
					.progress-wrapper {
						position: relative;
						width: 70px;
						height: 70px;
						margin-left: 20px;
						z-index: 10;
						& > .part-circular-progress {
							width: 70px;
							height: 70px;
						}
						& > .progress-bg {
							position: absolute;
							left: 0;
							right: 0;
							top: 0;
							z-index: -1;
						}
					}

					& > .lesson-contents {
						margin-left: 20px;
						& > .lesson-title {
							position: relative;
							font-weight: bold;
							color: #fff;
							text-shadow: 1px 1px 3px #222;
							margin-bottom: 10px;
						}
						& > .start-btn {
							display: inline-block;
							text-align: center;
							font-weight: bold;
							width: 90px;
							height: 24px;
							background: #fff;
							border-radius: 12px;
							margin: 5px 0;
						}
						& > .done-badge{
							margin-left: 10px;
						}
					}
				}
			}
			.am-accordion-header {
				display: flex;
				align-items: center;
				border: 1.5px solid rgba(103, 113, 116, 0.3);
				background: @common-bg-color;
				border-radius: 10px;
				margin-bottom: 10px;
				height: 65px; // part list item height
				color: #fff;
				font-size: 0.94rem;
				font-weight: bold;
				cursor: pointer;
				& > .arrow {
					display: none;
				}
				&::after {
					background: 0 !important;
				}
			}
			.am-accordion-content {
				background: transparent;
				border-radius: 5px;
				&-box {
					border-bottom: 0 !important;
					&::after {
						background: 0 !important;
					}
				}
			}
		}
	}
}
