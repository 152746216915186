html{
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'NotoSans', Noto Sans CJK KR;
	min-height: 640px;
}

div {
	font-family: 'NotoSans';
}

p {
	font-family: 'NotoSans';
}

button {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	box-shadow: none;
}

body[orient='portrait'] {
	min-height: 640px;
}

body[orient='landscape'] {
	min-height: 480px;
}

@media (max-width: 768px) {
	h1 {
		font-size: 1.7rem;
	}
}

#root {
	position: absolute;
	height: inherit;
	width: 100%;
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;