@import url('@common/Popup');
.bottom-tab-bar {
	position: fixed;
	width: 100%;
	bottom: 0;
	z-index: 100;
	cursor: pointer;
	.icon {
		width: 22px;
		height: 22px;
	}
	.icon-on {
		width: 22px;
		height: 22px;
	}
	.popup();
	.popup-background{
		animation: fadein 0.4s;
	}
	.popup-name {
		height: 70%;
		padding: 10px;
		font: 600 1.563em 'NotoSans', sans-serif;
		overflow: auto;
		display: flex;
		justify-content: center;
    justify-content: space-around;
    flex-direction: column;
		// align-items: center;
	}
  .history{
    padding: 0 15px;
    font-size:0.938rem;
    display:flex;
    align-items: center;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    & > .level{
      margin-right:10px;
    }
    & > .part-num{
      margin-right: 5px;
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      min-width: 25px;
      height: 25px;
			line-height: 25px;
      border: 2px solid #08c1bb;
      border-radius: 50%;
    }
    & > .title{
      word-break: keep-all;
      width: 148px;

    }
  }
	& > .button-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 80px;
		& > button {
			height: 30%;
			width: 50%;
			background: rgba(20, 23, 25, 0.9);
			font: 700 0.9em 'NotoSans', sans-serif;
			border: 1.5px solid rgba(103, 113, 116, 0.3);
			width: 50%;
			&:active {
				cursor: pointer;
				background: rgba(0, 0, 0, 0.1);
			}
		}
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;