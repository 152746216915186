.dialogue-container {
  z-index: 10;
}
.dialogue-container .pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.dialogue-container .pagination .am-pagination-wrap-dot-active span {
  border-radius: 4px;
  opacity: 0.9;
  width: 25px;
  background: rgba(200, 255, 252, 0.9);
}
.dialogue-container .coach-sentence {
  position: absolute;
  right: 0;
  top: 10%;
  z-index: 100;
  margin-right: 15px;
  animation: fadein 0.6s;
}
.dialogue-container > canvas {
  background: rgba(20, 23, 25, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  animation: fadein 0.6s;
}
.dialogue-container > button {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  margin: 15px;
  padding: 0;
  background: none;
  border: none;
}
.dialogue-container .top {
  height: 570px;
}
.dialogue-container .top#dialogue-scroll {
  overflow: auto;
  padding-bottom: 100px;
}
.dialogue-container .top .bubble-container {
  margin-top: 26px;
  padding: 0 10px;
}
.dialogue-container .top .bubble-container .bubble-box {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.dialogue-container .top .bubble-container .bubble-box:last-child {
  animation: fadein 0.6s;
  margin-bottom: 40px;
}
.dialogue-container .top .bubble-container .bubble-box .profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 6px;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper {
  display: inline-block;
  width: inherit;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .person-name {
  color: #fff;
  font-weight: bold;
  margin: 0;
  min-height: 22px;
  white-space: nowrap;
  text-align: initial;
  text-shadow: 1px 1px 2px #000;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .sub-badge {
  width: 30px;
  height: 30px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  margin: 15px 15px;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .sub-badge.left {
  display: none;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .sub-badge.right {
  float: right;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble {
  position: relative;
  height: fit-content;
  width: fit-content;
  max-width: 75%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 17px;
  padding: 15px;
  vertical-align: top;
  text-align: left;
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.2);
  border: 1.5px solid rgba(103, 113, 116, 0.3);
  margin: 0;
  color: #fff;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble.left {
  border-top-left-radius: 0px;
  float: left;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble.right {
  border-top-right-radius: 0px;
  float: right;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble.on {
  opacity: 1;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble.result {
  cursor: pointer;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble > .message {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 7px;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble > .message span {
  color: #fff;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble > .message span.word {
  display: inline-block;
  padding-right: 6px;
  white-space: nowrap;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble > .message span.word:last-child {
  padding-right: 0;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble > .message span.block {
  display: inline-block;
  color: #56fff9;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble > .sub {
  font-size: 0.75rem;
  font-weight: lighter;
  margin-bottom: 5px;
}
.dialogue-container .top .bubble-container .bubble-box .bubble-wrapper > .bubble > .sub.en {
  opacity: 0.6;
  margin-bottom: 0;
}
.dialogue-container .role-list-bg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogue-container .role-list-bg .role-list {
  position: relative;
  top: -100px;
}
.dialogue-container .role-list-bg .role-list > .back-btn {
  position: absolute;
  top: -30px;
  right: -20px;
  cursor: pointer;
}
.dialogue-container .role-list-bg .role-list-item {
  margin-bottom: 20px;
  cursor: pointer;
}
.dialogue-container .dimed-bg-bottom {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 30px;
  animation: fadein 0.6s;
}
.dialogue-container .dimed-bg-bottom .voca-widget {
  width: 52px;
  height: 52px;
  margin: 5px;
  border: 0;
  align-items: center;
}
.dialogue-container .set-bottom {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogue-container .set-bottom .voca-widget {
  width: 52px;
  height: 52px;
  margin: 5px;
  border: 0;
  align-items: center;
  cursor: pointer;
}
.dialogue-container .btn-frame.small-font {
  padding: 0 10px;
  font-size: 0.9rem;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.study-result-container {
  margin-top: 25px;
  z-index: 10;
}
.study-result-container span.word {
  display: inline-block;
  padding-right: 6px;
  white-space: nowrap;
}
.study-result-container span.word:last-child {
  padding-right: 0;
}
.study-result-container span.block {
  display: inline-block;
  color: #08c1bb;
}
.study-result-container > .top {
  padding: 0 20px;
}
.study-result-container > .top .badges {
  position: relative;
  display: flex;
  width: 100%;
  height: 120px;
  margin-bottom: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1.5px solid rgba(103, 113, 116, 0.3);
  border-radius: 12px;
  align-items: center;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
}
.study-result-container > .top .badges .current-badge {
  width: 40%;
  padding: 5px;
  -webkit-animation: swirl-in-fwd 0.6s ease-out both;
  animation: swirl-in-fwd 0.6s ease-out both;
  animation-iteration-count: 1;
}
.study-result-container > .top .badges .current-badge .badge-img {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  z-index: 10;
  border: 4px solid #08c1bb;
  border-radius: 50%;
}
@-webkit-keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}
@keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}
.study-result-container > .top .badges .learning-result {
  width: 60%;
}
.study-result-container > .top .badges .learning-result .result-box {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.study-result-container > .top .badges .learning-result .result-box div {
  flex: 0 0 auto;
}
.study-result-container .result-swiper-header {
  padding-left: 20px;
  color: #fff;
}
.study-result-container .swiper-container {
  width: 100%;
}
.study-result-container .swiper-pagination {
  padding-bottom: 10px;
  height: 32px;
}
.study-result-container .swiper-pagination > .swiper-pagination-bullet {
  background: #fff;
  transition: all 0.2s linear;
  border-radius: 4px;
  opacity: 0.5;
}
.study-result-container .swiper-pagination > .swiper-pagination-bullet-active {
  width: 25px;
  opacity: 0.9;
}
.study-result-container .swiper-wrapper {
  padding-inline-start: 0;
}
.study-result-container .swiper-wrapper .swiper-slide {
  margin-top: 60px;
  list-style: none;
  transition: margin-top 0.6s ease 0s;
}
.study-result-container .swiper-pagination > .swiper-pagination-bullet {
  box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.3);
}
.study-result-container .swiper-pagination > .swiper-pagination-bullet-active {
  background: rgba(200, 255, 252, 0.9);
}
.study-result-container .swiper-pagination > .swiper-pagination-bullet.grammar {
  background-color: rgba(8, 193, 187, 0.9);
}
.study-result-container .swiper-wrapper > .swiper-slide {
  margin-top: 0;
  margin-bottom: 32px;
}
.study-result-container .swiper-container {
  background: transparent;
  overflow: hidden;
  padding: 10px;
}
.study-result-container .swiper-container > .swiper-button-prev,
.study-result-container .swiper-container .swiper-button-next {
  top: 40%;
  color: #fff !important;
  visibility: hidden;
}
.study-result-container .swiper-container .card {
  position: relative;
  display: flex;
  width: 90%;
  max-width: 400px;
  height: 100%;
  min-height: 200px;
  max-height: 250px;
  vertical-align: top;
  background-color: rgba(20, 23, 25, 0.7);
  border: 1.5px solid rgba(103, 113, 116, 0.3);
  border-radius: 22px;
  min-width: 260px;
  color: #fff;
  overflow-y: auto;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 40px;
  flex-direction: column;
}
.study-result-container .swiper-container .card .bubble-container {
  margin-top: 26px;
  padding: 0 10px;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box:last-child {
  animation: fadein 0.6s;
  margin-bottom: 40px;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 6px;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper {
  display: inline-block;
  width: inherit;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .person-name {
  color: #fff;
  font-weight: bold;
  margin: 0;
  min-height: 22px;
  white-space: nowrap;
  text-align: initial;
  text-shadow: 1px 1px 2px #000;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .sub-badge {
  width: 30px;
  height: 30px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  margin: 15px 15px;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .sub-badge.left {
  display: none;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .sub-badge.right {
  float: right;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble {
  position: relative;
  height: fit-content;
  width: fit-content;
  max-width: 75%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 17px;
  padding: 15px;
  vertical-align: top;
  text-align: left;
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.2);
  border: 1.5px solid rgba(103, 113, 116, 0.3);
  margin: 0;
  color: #fff;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble.left {
  border-top-left-radius: 0px;
  float: left;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble.right {
  border-top-right-radius: 0px;
  float: right;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble.on {
  opacity: 1;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble.result {
  cursor: pointer;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble > .message {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 7px;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble > .message span {
  color: #fff;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble > .message span.word {
  display: inline-block;
  padding-right: 6px;
  white-space: nowrap;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble > .message span.word:last-child {
  padding-right: 0;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble > .message span.block {
  display: inline-block;
  color: #56fff9;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble > .sub {
  font-size: 0.75rem;
  font-weight: lighter;
  margin-bottom: 5px;
}
.study-result-container .swiper-container .card .bubble-container .bubble-box .bubble-wrapper > .bubble > .sub.en {
  opacity: 0.6;
  margin-bottom: 0;
}
.study-result-container .swiper-container .card.intro {
  padding-top: 30px;
}
.study-result-container .swiper-container .card > .top {
  padding-top: 2.5%;
}
.study-result-container .swiper-container .card > .top .speaker {
  border: 0;
  margin-left: 2.5%;
  padding: 0;
  background: none;
  float: left;
}
.study-result-container .swiper-container .card > .top .small-badge {
  color: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  float: left;
  margin-left: 2.5%;
  vertical-align: middle;
}
.study-result-container .swiper-container .card .title {
  color: inherit;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0;
  padding: 0 12px;
}
.study-result-container .swiper-container .card p {
  margin: 0 auto;
  width: 270px;
}
.study-result-container .btn-bundle .wrapper {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: 0 auto;
}
.study-result-container .btn-bundle .wrapper .home-btn {
  height: 60px;
  width: fit-content;
  padding: 0 25px;
  white-space: nowrap;
  margin: 5px;
  border-radius: 30px;
  border: 3px solid #d6dce5;
  background: #08c1bb;
  box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
}
