@normal-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);
@import '@common/CarouselSwiper';

.bubble-container () {
	.bubble-container {
		margin-top: 26px;
		padding: 0 10px;
		.bubble-box {
			position: relative;
			display: flex;
			width: 100%;
			margin-bottom: 20px;
			&:last-child {
				animation: fadein 0.6s;
				margin-bottom: 40px;
			}
			.profile-img {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				border: 2px solid #fff;
				margin: 6px;
			}
			.bubble-wrapper {
				display: inline-block;
				width: inherit;

				& > .person-name {
					color: #fff;
					font-weight: bold;
					margin: 0;
					min-height: 22px;
					white-space: nowrap;
					text-align: initial;
					text-shadow: 1px 1px 2px #000;
				}
				& > .sub-badge {
					width: 30px;
					height: 30px;
					float: right;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					font-weight: bold;
					margin: 15px 15px;
					&.left {
						display: none;
						// float: left;
					}
					&.right {
						float: right;
					}
				}
				& > .bubble {
					position: relative;
					height: fit-content;
					width: fit-content;
					max-width: 75%;
					background: rgba(0, 0, 0, 0.7);
					border-radius: 17px;
					padding: 15px;
					vertical-align: top;
					text-align: left;
					box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.2);
					border: 1.5px solid rgba(103, 113, 116, 0.3);
					margin: 0;
					color: #fff;

					&.left {
						border-top-left-radius: 0px;
						float: left;
					}
					&.right {
						border-top-right-radius: 0px;
						float: right;
					}
					&.on {
						opacity: 1;
						// color: rgb(86, 255, 249);
					}
					&.result {
						cursor: pointer;
					}
					& > .message {
						font-size: 0.9rem;
						font-weight: bold;
						margin-bottom: 7px;
						span {
							color: #fff;
							&.word {
								display: inline-block;
								padding-right: 6px;
								white-space: nowrap;
								&:last-child{
									padding-right: 0;
								}
							}
							&.block {
								display: inline-block;
								color: rgb(86, 255, 249);
							}
						}
						// &.right {
						// 	color: #fff;
						// }
						// &.left .block {
						// 	color: #fff;
						// }
					}
					& > .sub {
						font-size: 0.75rem;
						font-weight: lighter;
						margin-bottom: 5px;
						&.en {
							opacity: 0.6;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

.dialogue-container {
	z-index: 10;
	.pagination {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		.am-pagination-wrap-dot-active {
			span {
				border-radius: 4px;
				opacity: 0.9;
				width: 25px;
				background: rgba(200, 255, 252, 0.9);
			}
		}
	}
	.coach-sentence {
		position: absolute;
		right: 0;
		top: 10%;
		z-index: 100;
		margin-right: 15px;
		animation: fadein 0.6s;
	}
	& > canvas {
		background: rgba(20, 23, 25, 0.7);
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 10;
		animation: fadein 0.6s;
	}
	& > button {
		position: fixed;
		right: 0;
		top: 0;
		// display: none;
		// margin: 50px 5px 5px;
		z-index: 1000;
		margin: 15px;
		padding: 0;
		background: none;
		border: none;
	}

	.top {
		height: 570px; // 수정 필요
		&#dialogue-scroll {
			overflow: auto;
			padding-bottom: 100px;
		}
		.bubble-container();
	}
	// .widget-container.bottom {
	// }

	.role-list-bg {
		display: flex;
		justify-content: center;
		align-items: center;
		.role-list {
			position: relative;
			top: -100px;
			& > .back-btn {
				position: absolute;
				top: -30px;
				right: -20px;
				cursor: pointer;
			}
			&-item {
				margin-bottom: 20px;
				cursor: pointer;
			}
		}
	}

	.dimed-bg-bottom {
		position: absolute;
		display: flex;
		align-items: center;
		bottom: 30px;
		animation: fadein 0.6s;
		.voca-widget {
			width: 52px;
			height: 52px;
			margin: 5px;
			border: 0;
			align-items: center;
		}
	}
	.set-bottom {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 10%;
		display: flex;
		justify-content: center;
		align-items: center;
		.voca-widget {
			width: 52px;
			height: 52px;
			margin: 5px;
			border: 0;
			align-items: center;
			cursor: pointer;
		}
	}
	.btn-frame.small-font {
		padding: 0 10px;
		font-size: 0.9rem;
	}
	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}
