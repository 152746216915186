@import '@common/CarouselSwiper';

.sentence-card-container {
	position: relative;
	z-index: 101;

	.coach-wrapper {
		display: block;
		width: fit-content;
		position: relative;
		margin: 60px auto 0;
		.coach-sub-button {
			position: absolute;
			z-index: 100;
			top: -40px;
			left: 0;
			margin-left: 10px;
		}
	}
	.coach-badge {
		position: fixed;
		z-index: 100;
		top: 5px;
		right: 5px;
		margin-left: 10px;
	}
	& > canvas {
		background: rgba(20, 23, 25, 0.7);
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 10;
		animation: fadein 0.6s;
	}

	span {
		&.word {
			display: inline-block;
			padding-right: 6px;
			// white-space: nowrap;
			&:last-child {
				padding-right: 0;
			}
		}
		&.block {
			display: inline-block;
			color: #08c1bb;
		}
	}
	.learning-card-view-wrapper {
		.grammar-indicator {
			display: flex;
			width: fit-content;
			max-width: 300px;
			color: #fff;
			margin: 5% auto 0;
			height: 30px;
			border-radius: 15px;
			background-color: rgba(20, 23, 25, 0.7);
			padding: 5px 0px;
			& > .grammar {
				width: 100px;
				border-right: 2px solid #fff;
				&:last-child {
					border-right: 0;
				}
				& > p {
					margin: 0 auto;
					width: fit-content;
					font-size: 12px;
					&.on {
						// border-bottom: 1px solid rgb(8, 193, 187);
						color: rgb(8, 193, 187);
					}
					&:first-child {
						border-left: 0;
					}
				}
			}
		}
	}

	.swiper {
		.grammar-card-view-wrapper .example-box {
			text-align: center;
			margin: 3px;
			border-radius: 15px;
			box-shadow: 2px 1px 1px rgba(20, 23, 25, 0.7);

			.example-sign {
				width: 60px;
			}
		}
		.mixin-swiper();
		.swiper-pagination {
			bottom: 0px;
			& > .swiper-pagination-bullet {
				box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.3);
				&-active {
					background: rgba(200, 255, 252, 0.9);
				}
				&.grammar {
					background-color: rgba(8, 193, 187, 0.9);
				}
			}
		}
		.swiper-wrapper .swiper-slide {
			margin-bottom: 15px;
			margin-top: 40px;
			&-active {
				margin-top: 35px;
			}
		}
		.card {
			position: relative;
			display: flex;
			width: 90%;
			max-width: 400px;
			height: 100%;
			max-height: 250px;
			vertical-align: top;
			background-color: rgba(20, 23, 25, 0.7);
			border: 1.5px solid rgba(103, 113, 116, 0.3);
			border-radius: 22px;
			min-width: 260px;
			color: #fff;
			overflow-y: auto;
			text-align: center;
			margin: 0 auto;
			padding-bottom: 40px;
			flex-direction: column;
			&.intro {
				padding-top: 30px;
			}
			& > .top {
				padding-top: 2.5%;
				.speaker {
					border: 0;
					margin-left: 2.5%;
					padding: 0;
					background: none;
					float: left;
				}
				.small-badge {
					color: #fff;
					width: 36px;
					height: 36px;
					line-height: 36px;
					float: left;
					margin-left: 2.5%;
					vertical-align: middle;
				}
			}
			.title-wrapper {
			}
			.title {
				color: inherit;
				font-weight: bold;
				font-size: 1.5rem;
				// line-height: 2rem;
				// font-size: 1.5vw;
				// font-size: clamp(16px, calc(15px + 1.5vw), 30px);
				// font-size: max(calc(15px + 1.1vw), 40px);
				// font-size: clamp(16px, 5vw, 25px);
				// font-size: calc(15px + 1.1vw);
				// font-size: 3rem;
				// font-size: max(15px, 2em);
				padding: 0 5%;
				// margin: 2% 0;
			}
			p {
				margin: 5px auto;
				width: 270px;
				&.instruction {
					opacity: 0.7;
				}
			}
		}
	}
	.widget-container.bottom {
		bottom: 10%;
		z-index: 10;
		.coach-bottom {
			position: absolute;
			z-index: 100;
			top: -60px;
		}
	}
	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;