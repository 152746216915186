.level-report-container {
	margin-top: 10px;
	width: 100%;
	padding: 0 5%;
	.level-title {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		padding: 12px 0;
		height: 50px;
		color: rgba(255, 255, 255);
		font-size: 1rem;
	}
	.part-box {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 10px 5px 10px;
		margin-bottom: 20px;
		.title-box {
			height: 22px;
			display: inline-block;
			float: left;
			.title-num {
				color: #fff;
				font-size: 0.9rem;
				font-weight: 600;
				float: left;
				justify-content: center;
				align-items: center;
				display: flex;
				width: 25px;
				height: 25px;
				line-height: 25px;
				border: 2px solid #fff;
				border-radius: 50%;
				font-weight: normal;
				margin-right: 10px;
			}
			.title {
				display: inline-block;
				color: #fff;
				font-size: 0.9rem;
				font-weight: 600;
				text-align: left;
				width: 150px;
				height: 20px;
				line-height: 20px;
				overflow: hidden; // 너비를 넘으면 내용에 대해서는 보이지 않게 처리
				white-space: nowrap;
				text-overflow: ellipsis; // 글자가 너비를 넘을 경우 생략부호 표시
			}
		}
		.score-box {
			font-size: 0.9rem;
			//font-weight: 700;
			height: 20px;
			color: #08c1bb;
			display: inline-block;
		}
		.badge-box {
			display: inline-block;
			float: right;
			.progress-num {
				color: rgba(255, 255, 255);
				font-size: 0.75rem;
				margin-top: 5px;
			}
			.ant-progress-inner {
				// background-image: url(../../assets/images/badge/star_badge_on.png);
			}
			.ant-progress-circle-path {
				stroke-linecap: square;
				stroke: #08c1bb;
				stroke-width: 10px;
			}
			.ant-progress-circle-trail {
				stroke: #d1d1d1;
			}
			.ant-progress-text {
				display: none;
				font-size: 1em;
				//margin-top: 25px;
			}
			img {
				width: 50px;
				height: 50px;
			}
		}
	}
	.part-box:last-child {
		margin-bottom: 50px;
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;