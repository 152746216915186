.app-container {
	height: 100%;
	z-index: -1000;
	overflow: hidden;
	// width: 100%;
}

.app-content {
	text-align: left;
	// background-image: linear-gradient(transparent, white);
}

p {
	margin: 0; // replace antd style
}
@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;