@swiper-margin-top: 60px;

.mixin-swiper() {
	.swiper-container {
		width: 100%;
	}
	.swiper-pagination {
		padding-bottom: 10px;
		height: 32px;
		& > .swiper-pagination-bullet {
			// margin-top: 32px;
			background: #fff;
			transition: all 0.2s linear;
			border-radius: 4px;
			opacity: 0.5;
			&-active {
				width: 25px;
				opacity: 0.9;
			}
		}
	}

	.swiper-wrapper {
		padding-inline-start: 0;
		.swiper-slide {
			margin-top: @swiper-margin-top;
			list-style: none;
			transition: margin-top 0.6s ease 0s;
		}
	}
}
