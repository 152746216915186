@import '@common/LightList.less';
@import url('@common/Popup');
// @import './LessonPopup.less';
@lesson-bg-color: rgba(32, 34, 36, 0.58);
@common-bg-color: rgba(20, 23, 25, 0.7);
@common-today-list-header-width: 334px;
@common-today-list-item-width: 334px;

.today-korean () {
	.today-korean {
		position: absolute;
		animation: fadein 0.6s;
		padding: 0 15px;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		.back-btn {
			position: absolute;
			background: transparent;
			border: none;
			height: 50px;
			width: 50px;
			left: 0;
			// height: 20px;
			cursor: pointer;
		}
		.today-korean-list-wrapper {
			padding-top: 60px;
			margin: 0 auto;
			width: @common-today-list-header-width;
			transition-duration: 0.3s;
		}
		.today-korean-header {
			width: 100%;
			height: 40px;
			line-height: 40px;
			padding-left: 30px;
			background-color: rgb(45, 146, 153);
			border-radius: 8px;
			color: #fff;
			font-size: 1rem;
			font-weight: bold;
			margin-bottom: 10px;
		}
		.today-korean-list .lesson-item {
			position: relative;
			background: @lesson-bg-color;
			width: 100%;
			border-radius: 10px;
			padding: 24px 0;
			margin-bottom: 14px;
			display: flex;
			cursor: pointer;
			& > img {
				margin-left: 20px;
				width: 70px;
				height: 70px;
			}
			& > .lesson-contents {
				margin-left: 10px;
				& > .lesson-title {
					position: relative;
					font-weight: bold;
					color: #fff;
					text-shadow: 1px 1px 3px #222;
					margin: 5px 0;
				}
				& > .start-btn {
					display: inline-block;
					text-align: center;
					font-weight: bold;
					width: 90px;
					height: 24px;
					background: #fff;
					border-radius: 12px;
					margin: 5px 0;
				}
			}
		}
	}
}
.popup();
.popup-background {
	&.on {
		animation: fadein 0.4s;
		.content {
			height: 70%;
			padding: 10px;
			font: 600 1.563em 'NotoSans', sans-serif;
			overflow: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}
		& > .button-wrap {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px 80px;
			& > button {
				height: 30%;
				width: 50%;
				background: rgba(20, 23, 25, 0.9);
				font: 700 0.9em 'NotoSans', sans-serif;
				border: 1.5px solid rgba(103, 113, 116, 0.3);
				width: 50%;
			}
		}
	}
}
