@normal-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);

.video-roleplay-container {
	z-index: 10;
	height: 100%;
	.video-roleplay-contents {
		height: inherit;
		display: flex;
		flex-direction: column;
		#video-roleplay-scroll {
			overflow: auto;
			padding-bottom: 30%;
		}
		.top.video-box {
			position: relative;
			width: 100%;
			flex: 0 0 auto;
			.video-wrapper {
				width: 100%;
				// max-height: 330px;
				background: rgba(20, 23, 25, 0.7);
			}
			.pagination {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				.am-pagination-wrap-dot-active {
					span {
						border-radius: 4px;
						opacity: 0.9;
						width: 25px;
						background: rgba(200, 255, 252, 0.9);
					}
				}
			}
			.control-btn {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
			.coach-sentence {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 100;
				margin-right: 15px;
				animation: fadein 0.6s;
			}
			.coach-sentence2 {
				position: fixed;
				align-items: center;
				bottom: 50px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 101;
			}
			& > canvas {
				background: rgba(20, 23, 25, 0.7);
				position: fixed;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 10;
				animation: fadein 0.6s;
			}
		}
	}
	.gradient-dimed-bg {
		background: linear-gradient(transparent 0%, black 100%);
		z-index: 10;
		position: absolute;
		width: 100%;
		height: 20%;
		bottom: 0;
		left: 0;
		right: 0;
		pointer-events: none;
		animation: fadein 0.3s;
	}
	.bubble-container {
		// margin-top:26px;
		padding: 0 10px;
		.bubble-box {
			position: relative;
			display: flex;
			width: 100%;
			margin-bottom: 20px;
			&:last-child {
				animation: fadein 0.6s;
				margin-bottom: 10%;
			}
			& > .profile-img {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				border: 2px solid #fff;
				margin: 6px;
			}
			.bubble-wrapper {
				display: inline-block;
				width: inherit;
				& > .person-name {
					color: #fff;
					font-weight: bold;
					margin: 0;
					min-height: 22px;
					white-space: nowrap;
					text-align: initial;
					text-shadow: 1px 1px 2px #000;
				}
				& > .sub-badge {
					width: 30px;
					height: 30px;
					float: right;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					font-weight: bold;
					margin: 15px 15px;
					&.left {
						display: none;
						// float: left;
					}
					&.right {
						float: right;
					}
				}
				.bubble {
					position: relative;
					height: fit-content;
					width: fit-content;
					max-width: 75%;
					background: rgba(0, 0, 0, 0.7);
					border-radius: 17px;
					padding: 15px;
					vertical-align: top;
					text-align: left;
					box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.2);
					border: 1.5px solid rgba(103, 113, 116, 0.3);
					margin: 0;
					color: #fff;
					&.left {
						border-top-left-radius: 0px;
						float: left;
					}
					&.right {
						border-top-right-radius: 0px;
						float: right;
					}
					.speaker {
						width: 22px;
						height: 22px;
						border: 0;
						margin: 5px 0;
						bottom: 0;
					}
					&:last-child {
						animation: fadein 0.6s;
					}
					// 스크립트
					& > .script {
						font-size: 0.9rem;
						font-weight: bold;
						margin-bottom: 7px;
						opacity: 0.4;
						color: #fff;
						&:last-child {
							margin-bottom: 0;
						}
						span {
							&.word {
								display: inline-block;
								padding-right: 6px;
								white-space: nowrap;
								&:last-child{
									padding-right: 0;
								}
							}
							&.block {
								display: inline-block;
								color: rgb(86, 255, 249);
							}
						}
						&.on {
							opacity: 1;
						}
						// &.user .block {
						// 	color: rgb(86, 255, 249);
						// }
						&.all .block {
							color: rgb(86, 255, 249);
						}
					}
				}
			}
		}
	}
	.bottom {
		position: fixed;
		margin: 0 auto;
		width: 100%;
		bottom: 10%;
		left: 0;
		.button-widget-box {
			position: relative;
			.grammar-next-btn {
				display: block;
				right: 0px;
				margin-left: auto;
			}
		}
		.bottom-widget-container {
			display: block;
			width: 100%;
			justify-content: center;
			padding: 15px;

			.widget-wrapper {
				display: flex;
				justify-content: center;
				.widget {
					width: 52px;
					height: 52px;
					margin: 7px;
					box-shadow: @normal-shadow;
					border-radius: 50%;
					border: 0;
					&.normal {
						box-shadow: @normal-shadow;
						border: 2px solid #fff;
						border-radius: 10px;
						font-weight: bold;
						&.on {
							box-shadow: 0;
						}
					}
				}
				.normal-btn {
					display: block;
					border: 10px;
					border-radius: 10px;
					width: 300px;
					height: 45px;
					margin: 5px;
					background-color: #fff;
					box-shadow: @normal-shadow;
				}
			}
		}
	}
	.btn-bundle > .btn-frame {
		padding: 0 10px;
		font-size: 0.9rem;
	}
	.role-list-bg {
		display: flex;
		justify-content: center;
		align-items: center;
		.role-list {
			position: relative;
			& > .back-btn {
				position: absolute;
				top: -10px;
				right: -15px;
				cursor: pointer;
			}
			&-item {
				margin-bottom: 15px;
				cursor: pointer;
			}
		}
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;