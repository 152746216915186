.grid-list () {
  .list-item-wrap {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    .list-item {
      font-size: 0.9rem;
      font-weight: bold;
      display: block;
      align-items: center;
      height: 100%;
      cursor: pointer;
    }
  }
}
