.full-screen-bg {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 101;
	width: 100%;
	height: 100%;
	margin: auto;
}
.signup-container {
	.signup-contents {
		// position: relative;
		padding: 5%;
		height: 100%;
		.pagination {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			.am-pagination-wrap-dot{
				transition: all 0.2s linear;
				& > span{
					transition: all 0.2s linear;
				}
				&-active {
					span {
						border-radius: 4px;
						opacity: 0.9;
						width: 25px;
						background: rgba(200, 255, 252, 0.9);
					}
				}
			}
		}
		// .am-pagination {
		// 	position: fixed;
		// 	top: 15px;
		// 	transform: translateX(-50%);
		// 	left: 50%;
		// }
		// .am-pagination-wrap-dot {
		// 	transition: all 0.2s linear;
		// 	& > span {
		// 		background-color: #fff;
		// 		opacity: 0.5;
		// 	}
		// 	&-active > span {
		// 		// animation: sizing 0.1s linear;
		// 		border-radius: 4px;
		// 		width: 25px;
		// 		background-color: #fff;
		// 		opacity: 0.9;
		// 		padding: 0 2px;
		// 	}
		// }
		.signup-form {
			text-align: center;
			.ant-form-item-control {
				// position: relative;
				margin-top: 30%;
				.form-item-sentence {
					color: #fff;
					font-size: 1.6rem;
					font-weight: 700;
					margin-bottom: 0;
					display: block;
					line-height: normal;
				}
				.signup-selector {
					#scrollbar {
						min-height: 190px;
						overflow-y: auto;
						overflow-x: hidden;
						position: absolute;
						width: 300px;
						display: contents;
					}

					// transform: translate();
					// left: 0;
					.ant-select-selector {
						height: 42px;
						margin-top: 30px;
						border-radius: 10px;
						background-color: rgba(20, 23, 25, 0.7);
						border: 1.5px solid rgba(103, 113, 116, 0.3);
						font-weight: regular;
						color: #fff;
					}
					.ant-select-selection-item {
						font-size: 0.9rem;
						line-height: 40px;
					}
					.ant-select-arrow {
						font-size: 1rem;
						font-weight: bold;
						color: rgba(255, 255, 255);
						margin-top: 7px;
					}
				}
				.signup-btn-bundle {
					position: fixed;
					width: 100%;
					left: 0;
					right: 0;
					margin: 0 auto;
					height: 10%;
					bottom: 10%;
					& > .signup-btn {
						position: absolute;
						bottom: 5%;
						width: 80px;
						height: 32px;
						border-radius: 21px;
						background-color: rgba(20, 23, 25, 0.7);
						border: 1.5px solid rgba(103, 113, 116, 0.3);
						color: #fff;
					}
					.previous-btn {
						left: 10%;
					}
					.next-btn {
						right: 10%;
					}
				}
			}
		}
		/* 국적 */
		.am-list-item {
			position: relative;
			margin-top: 20%;
			.am-list-line {
				color: #dedede;
			}
		}

		/* 성별 */
		.ant-radio-group {

      position: absolute;
      transform: translate(-50%, -50%);
			margin-top: 10%;
			height: 30px;
			line-height: 30px;
			.ant-radio-wrapper:hover {
				border-color: none;
			}
			.ant-radio-wrapper {
				
        display: flex;
        margin-right: 0;
				margin-top: 25%;
				
				height: 30px;
				line-height: 30px;
				.ant-radio {
					width: 18px;
					height: 18px;
					vertical-align: baseline;
					margin-right: 35px;
					.ant-radio-input {
						width: 30px;
						height: 30px;
					}
					.ant-radio-inner {
						width: 23px;
						height: 23px;
						border-color: rgba(255, 255, 255);
						//border: solid rgba(255,255,255);
						border-radius: 15px;
						border-width: 2px;
						background-color: rgba(20, 23, 25, 0.5);
					}
					.ant-radio-inner:after {
						position: absolute;
						width: 22px;
						height: 22px;
						top: -2px;
						left: -2px;
						border-radius: 15px;
						background-color: rgba(255, 255, 255);
					}
				}
				.ant-radio-checked .ant-radio-inner {
					border-color: rgba(255, 255, 255);
				}
				span {
					color: rgba(255, 255, 255);
					font-size: 1.1rem;
					font-family: 'NotoSans', sans-serif;
					//font-weight: 700;
				}
			}
		}
	}
}
.am-picker-popup-body {
	.am-picker-popup-item {
		color: #6b75ac !important;
	}
	.am-picker-col {
		height: 50vh !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.am-list-item {
		height: 60px;
	}
}
@media screen and (min-width: 1025px) {
	.am-list-item {
		max-height: 60px;
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;