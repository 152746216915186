.recording-dots() {
	.recording-dots-container {
		display: flex;
	}
	.recording-dot-wrap {
		transition: all 0.2s linear;
		display: inline-block;
		zoom: 1;

		& > span {
			display: block;
			border-radius: 4px;
			margin: 0 3px;
			width: 5px;
			height: 5px;
			background-color: #fff;
		}
		&-active > span {
			// animation: sizing 0.1s linear;
			width: 5px;
			background-color: rgb(255, 0, 0);
			opacity: 0.9;
			padding: 0 2px;
		}
	}
}
