.terms-content {
	padding: 40% 5% 0;
	// margin-top: 50%;
	height: 100%;
	width: 100%;

	.terms-detail {
		word-break: break-all;
	}

	.terms-checkbox {
		.my-accordion .pad .am-accordion-content-box {
			padding: 10px;
		}
		.am-accordion .am-accordion-item {
			.am-accordion-header {
				font-size: 0.874rem !important;
				&::before {
					content: '(Required) ';
					color: red;
				}
			}
			.am-accordion-content {
				max-height: 200px;
				overflow: auto !important;
				border-bottom: 1px solid #dfdfdf;
			}
		}
		.picker-list .am-list-item .am-list-line .am-list-extra {
			flex-basis: initial;
		}
	}
	.btn-box {
		display: flex;
		justify-content: center;
		.terms-btn {
			position: fixed;
			bottom: 5%;
			margin-top: 10px;
			width: 80px;
			height: 32px;
			border-radius: 21px;
			background-color: rgba(20, 23, 25, 0.7);
			border: 1.5px solid rgba(103, 113, 116, 0.3);
			font-weight: bold;
			color: #fff;
		}
	}
	.sign-name {
		font-weight: 400;
		.tit-sign {
			background: #fff;
			margin: 10px 0 6px;
			padding: 6px 10px;
			font-weight: 600;
			font-size: 20px;
		}
		dl {
			background: #fff;
			margin-bottom: 0;
			dt {
				padding: 5px;
				font-size: 16px;
			}
			dd {
				margin-bottom: 0;
				input {
					width: 100%;
					padding: 12px;
					background: #dfdfdf;
					border: 0 none;
					font-size: 16px;
				}
			}
			.txt-accent {
				color: red;
			}
		}
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;