@import '@common/CarouselSwiper';

.part-report-container {
	width: 100%;
	height: 100%;
	position: relative;

	.carousel-part-container {
		// height: 100%;
		overflow: auto;
		.mixin-swiper();
		.page-title{
			position: absolute;
			color: #fff;
			left: 50%;
			transform: translateX(-50%);
      margin-top: 2px;
    }
		.swiper-container {
			padding-top: 30px;
			& > .swiper-button-next {
				top: 13px;
				right: 36%;
				color: rgb(255, 255, 255);
			}

			& > .swiper-button-prev {
				top: 13px;
				left: 36%;
				color: rgb(255, 255, 255);
			}
			::after {
				font-size: 15px;
			}

			.swiper-wrapper {
				padding: 0;
				margin: 0;
				overflow: visible;
				// margin-top: 25px;
				.step-swiper-slide {
					padding: 0 5%;
					overflow: auto;
					position: relative;
				}
				.swiper-slide {
					margin-top: 0;
					& > .page-title {
						display: flex;
						justify-content: center;
						color: rgb(255, 255, 255);
					}
					& > .grammar-card {
						width: 90%;
						max-width: 430px;
						min-height: 210px;
						vertical-align: top;
						background-color: rgba(0, 0, 0, 0.7);
						// border: 0.5px solid #333;
						border: 1.5px solid rgba(103, 113, 116, 0.3);
						border-radius: 22px;
						min-width: 260px;
						color: #fff;
						overflow: hidden;
						text-align: center;
						margin: 15px auto;
						padding: 32px 0;
						display: flex;
						flex-direction: column;
					}
					.title {
						color: inherit;
						font-weight: bold;
						margin: 0;
					}
					.instruction {
						margin: 5px auto;
						width: 270px;
						opacity: 0.7;
					}
					& > .score-box {
						background-color: rgba(20, 23, 25, 0.7);
						background-repeat: no-repeat;
						background-size: contain;
						border-radius: 15px;
						border: 1.5px solid rgba(103, 113, 116, 0.3);
						display: flex;
						flex-direction: row;
						justify-content: space-around;
						align-items: center;
						padding: 15px;
						margin: 15px 0;
						p {
							margin: none;
							color: #fff;
							font-size: 1.56rem;
							font-weight: 500;
						}
						.score {
							font-size: 1.44rem;
							font-weight: 700;
							color: rgb(8, 193, 187);
							padding-right: 40px;
						}
						.badge {
							display: flex;
							flex-direction: column;
							align-items: center;
							& > .bedge-img {
								width: 50px;
								height: 50px;
							}
							img {
								width: 50px;
								height: 50px;
							}
						}
					}
					& > .radar-box {
						background-color: rgba(20, 23, 25, 0.7);
						border-radius: 15px;
						border: 1.5px solid rgba(103, 113, 116, 0.3);
						background-size: contain;
						background-repeat: no-repeat;
						padding: 0 20px;
						display: flex;
						justify-content: center;
						margin: 5px 0 15px 0;
						.radar {
							// height: 250px;
						}
					}
					& > .header-title {
            margin-left:5px;
						color: #fff;
						font-weight: bold;
					}
					// 교사 코멘트
					& > .comment-box {
						background-color: rgba(20, 23, 25, 0.7);
						background-repeat: no-repeat;
						border-radius: 15px;
						border: 1.5px solid rgba(103, 113, 116, 0.3);
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 30px;
						color: #7f7f7f;
						margin-bottom: 15px;
					}
					// 문장 박스
					& > .detail-box {
						display: flex;
						flex-direction: column;
						margin: 5px 0 50px;
						.video {
							border-radius: 10px;
						}
						.sentence-group {
							margin-bottom: 5px;
						}
						& > .role-btn-wrapper {
							display: flex;
							justify-content: center;
						}
						.sentence-box {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-bottom: 5px;
							width: inherit;
							&:last-child {
								margin-bottom: 0;
							}
							.left-side {
								display: flex;
								& > .profile-img {
									height: 100%;
									margin-right: 5px;
									border-radius: 50%;
								}
								& > .sentence {
									word-break: keep-all;
									min-height: 30px;
									color: white;
									font-size: 0.9rem;
									opacity: 0.5;
									display: flex;
									align-items: center;
									&.audio {
										opacity: 1;
									}
								}
							}
							.right-side {
								flex: 0 0 auto;
								.score-badge {
									width: 30px;
									height: 30px;
									color: #fff;
									font-weight: bold;
									display: flex;
									justify-content: center;
									align-items: center;
									float: left;
								}
								.play-btn {
									float: right;
									background-color: rgba(0, 0, 0, 0);
									border: 0;
									margin-left: 5px;
									img {
										width: 30px;
										height: 30px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;