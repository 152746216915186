.popup-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(21, 25, 29, 0.7);
  left: 0;
  top: 0;
  z-index: 102;
}
.popup-background .popup {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 342px;
  height: 216px;
  margin: auto;
  background: rgba(20, 23, 25, 0.9);
  border-radius: 20px;
  border: 1.5px solid rgba(103, 113, 116, 0.3);
  color: rgba(239, 239, 239);
}
.popup-background.on {
  animation: fadein 0.4s;
}
.popup-background.on .content {
  height: 70%;
  padding: 10px;
  font: 600 1.563em 'NotoSans', sans-serif;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.popup-background.on > .button-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 80px;
}
.popup-background.on > .button-wrap > button {
  height: 30%;
  background: rgba(20, 23, 25, 0.9);
  font: 700 0.9em 'NotoSans', sans-serif;
  border: 1.5px solid rgba(103, 113, 116, 0.3);
  width: 50%;
}
@media only screen and (max-width: 1280px) {
  .home-swiper-wrapper .nav-btn {
    display: none;
  }
}
.home-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
  z-index: 1;
}
.home-container #home {
  position: relative;
  margin: 0 auto;
  height: 100%;
}
.home-container .today-korean {
  position: absolute;
  animation: fadein 0.6s;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.home-container .today-korean .back-btn {
  position: absolute;
  background: transparent;
  border: none;
  height: 50px;
  width: 50px;
  left: 0;
  cursor: pointer;
}
.home-container .today-korean .today-korean-list-wrapper {
  padding-top: 60px;
  margin: 0 auto;
  width: 334px;
  transition-duration: 0.3s;
}
.home-container .today-korean .today-korean-header {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 30px;
  background-color: #2d9299;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.home-container .today-korean .today-korean-list .lesson-item {
  position: relative;
  background: rgba(32, 34, 36, 0.58);
  width: 100%;
  border-radius: 10px;
  padding: 24px 0;
  margin-bottom: 14px;
  display: flex;
  cursor: pointer;
}
.home-container .today-korean .today-korean-list .lesson-item > img {
  margin-left: 20px;
  width: 70px;
  height: 70px;
}
.home-container .today-korean .today-korean-list .lesson-item > .lesson-contents {
  margin-left: 10px;
}
.home-container .today-korean .today-korean-list .lesson-item > .lesson-contents > .lesson-title {
  position: relative;
  font-weight: bold;
  color: #fff;
  text-shadow: 1px 1px 3px #222;
  margin: 5px 0;
}
.home-container .today-korean .today-korean-list .lesson-item > .lesson-contents > .start-btn {
  display: inline-block;
  text-align: center;
  font-weight: bold;
  width: 90px;
  height: 24px;
  background: #fff;
  border-radius: 12px;
  margin: 5px 0;
}
.home-container .part-container {
  animation: fadein 1.2s;
  height: 100%;
  width: 334px;
  margin: 0 auto;
  overflow: auto;
  max-width: 1000px;
  padding-top: 10px;
  transition-duration: 0.6s;
}
.home-container .part-container .part-accordion {
  border-top: 0;
}
.home-container .part-container .part-accordion::before {
  display: none;
}
.home-container .part-container .part-accordion .part-panel .list-item-wrap {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.home-container .part-container .part-accordion .part-panel .list-item-wrap .list-item {
  font-size: 0.9rem;
  font-weight: bold;
  display: block;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.home-container .part-container .part-accordion .part-panel .part-header-num {
  display: flex;
  width: 25px;
  height: 25px;
  line-height: 25px;
  justify-content: center;
  align-items: center;
  float: left;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: 5px;
  font-weight: normal;
}
.home-container .part-container .part-accordion .part-panel .lesson-item {
  position: relative;
  background: rgba(32, 34, 36, 0.58);
  border: 1.5px solid rgba(103, 113, 116, 0.3);
  margin-bottom: 14px;
  border-radius: 10px;
  padding: 24px 0;
  display: flex;
  height: 118px;
}
.home-container .part-container .part-accordion .part-panel .lesson-item .progress-wrapper {
  position: relative;
  width: 70px;
  height: 70px;
  margin-left: 20px;
  z-index: 10;
}
.home-container .part-container .part-accordion .part-panel .lesson-item .progress-wrapper > .part-circular-progress {
  width: 70px;
  height: 70px;
}
.home-container .part-container .part-accordion .part-panel .lesson-item .progress-wrapper > .progress-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}
.home-container .part-container .part-accordion .part-panel .lesson-item > .lesson-contents {
  margin-left: 20px;
}
.home-container .part-container .part-accordion .part-panel .lesson-item > .lesson-contents > .lesson-title {
  position: relative;
  font-weight: bold;
  color: #fff;
  text-shadow: 1px 1px 3px #222;
  margin-bottom: 10px;
}
.home-container .part-container .part-accordion .part-panel .lesson-item > .lesson-contents > .start-btn {
  display: inline-block;
  text-align: center;
  font-weight: bold;
  width: 90px;
  height: 24px;
  background: #fff;
  border-radius: 12px;
  margin: 5px 0;
}
.home-container .part-container .part-accordion .part-panel .lesson-item > .lesson-contents > .done-badge {
  margin-left: 10px;
}
.home-container .part-container .part-accordion .am-accordion-header {
  display: flex;
  align-items: center;
  border: 1.5px solid rgba(103, 113, 116, 0.3);
  background: rgba(20, 23, 25, 0.7);
  border-radius: 10px;
  margin-bottom: 10px;
  height: 65px;
  color: #fff;
  font-size: 0.94rem;
  font-weight: bold;
  cursor: pointer;
}
.home-container .part-container .part-accordion .am-accordion-header > .arrow {
  display: none;
}
.home-container .part-container .part-accordion .am-accordion-header::after {
  background: 0 !important;
}
.home-container .part-container .part-accordion .am-accordion-content {
  background: transparent;
  border-radius: 5px;
}
.home-container .part-container .part-accordion .am-accordion-content-box {
  border-bottom: 0 !important;
}
.home-container .part-container .part-accordion .am-accordion-content-box::after {
  background: 0 !important;
}
.home-container .swiper-container {
  width: 100%;
}
.home-container .swiper-pagination {
  padding-bottom: 10px;
  height: 32px;
}
.home-container .swiper-pagination > .swiper-pagination-bullet {
  background: #fff;
  transition: all 0.2s linear;
  border-radius: 4px;
  opacity: 0.5;
}
.home-container .swiper-pagination > .swiper-pagination-bullet-active {
  width: 25px;
  opacity: 0.9;
}
.home-container .swiper-wrapper {
  padding-inline-start: 0;
}
.home-container .swiper-wrapper .swiper-slide {
  margin-top: 60px;
  list-style: none;
  transition: margin-top 0.6s ease 0s;
}
.home-container .home-swiper-wrapper {
  position: relative;
  height: 100%;
}
.home-container .home-swiper-wrapper .nav-btn {
  top: 50%;
}
.home-container .home-swiper-wrapper > img {
  position: absolute;
  top: 50%;
  z-index: 100;
  margin: 10px;
}
.home-container .home-swiper-wrapper > img.left {
  left: 0;
}
.home-container .home-swiper-wrapper > img.right {
  right: 0;
}
.home-container .home-swiper-wrapper .coach {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  margin: 0;
  animation: fadein 0.6s;
}
.home-container .home-swiper-wrapper .coach.top {
  top: 0;
  margin-top: 10rem;
}
.home-container .home-swiper-wrapper > canvas {
  background: rgba(20, 23, 25, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  animation: fadein 0.6s;
}
.home-container .home-swiper-wrapper > button {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  margin: 15px;
  background: none;
  border: none;
}
.home-container .swiper-container {
  height: 100%;
}
.home-container .swiper-wrapper {
  margin-bottom: 0;
}
.home-container .swiper-wrapper .swiper-slide {
  padding-top: 60px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
}
.home-container .swiper-wrapper .swiper-slide > .img-wrapper {
  position: relative;
  width: 283px;
  height: 392px;
  margin: 0 auto;
  overflow: hidden;
  transition-duration: 0.6s;
  box-shadow: 0.5px 1px 2px #555;
  border-radius: 20px;
}
.home-container .swiper-wrapper .swiper-slide > .img-wrapper.on {
  width: 334px;
  height: 42px;
  border-radius: 5px;
}
.home-container .swiper-wrapper .swiper-slide > .img-wrapper .title {
  position: absolute;
  top: 0;
  left: 0;
  margin: 6px 30px;
  z-index: 100;
  font-size: 2.5rem;
  color: #674498;
  font-weight: bold;
  white-space: nowrap;
  visibility: hidden;
}
.home-container .swiper-wrapper .swiper-slide > .img-wrapper .title.on {
  animation: fadein 0.6s;
  font-size: 1.125rem;
  color: #fff;
  visibility: visible;
}
.home-container .swiper-wrapper .swiper-slide > .img-wrapper .level-thumbnail {
  position: relative;
  width: 100%;
  height: 400px;
  cursor: pointer;
}
.home-container .swiper-wrapper .swiper-slide > .img-wrapper .level-thumbnail.on {
  background: top;
  filter: brightness(0.7);
}
.home-container .swiper-wrapper .swiper-slide > .img-wrapper .level-thumbnail img {
  position: absolute;
}
.home-container .swiper-wrapper .swiper-slide > .part-list-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.home-container .swiper-pagination {
  top: 24px;
}
.home-container .swiper-pagination > .swiper-pagination-bullet {
  background: #fff;
}
.home-container .help-btn {
  position: absolute;
  padding: 10px;
  right: 0;
  cursor: pointer;
  z-index: 101;
}
.home-container .bottom {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 10;
}
.home-container .todays-korean-btn {
  background-color: rgba(8, 193, 187);
  border: 3px solid rgba(213, 249, 243);
  color: #fff;
  text-shadow: 0.5px 1px 1px #222;
  box-shadow: 0.5px 1px 1px #222;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 50%;
  margin: 20px;
  width: 60px;
  height: 60px;
  animation: fadein 0.6s;
  -webkit-animation: fadein 0.6s;
  /* Safari and Chrome */
  cursor: pointer;
}
.coach-mark-swiper-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  z-index: 1000000;
  background-color: #141719;
}
.coach-mark-swiper-popup .close-coach {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  z-index: 100;
}
.coach-mark-swiper-popup .cm-nav-btn {
  position: absolute;
  z-index: 100;
  top: 40%;
}
.coach-mark-swiper-popup .cm-nav-btn.right {
  right: 0px;
}
.coach-mark-swiper-popup .cm-nav-btn.left {
  left: 0px;
}
.coach-mark-swiper-popup .swiper-container {
  width: 100%;
  height: 100%;
}
.coach-mark-swiper-popup .swiper-container > .swiper-pagination {
  top: 15px;
  height: 32px;
}
.coach-mark-swiper-popup .swiper-container > .swiper-pagination > .swiper-pagination-bullet {
  background: #fff;
  transition: all 0.2s linear;
  border-radius: 4px;
  opacity: 0.5;
}
.coach-mark-swiper-popup .swiper-container > .swiper-pagination > .swiper-pagination-bullet-active {
  width: 25px;
  opacity: 0.9;
}
.coach-mark-swiper-popup .swiper-container > .swiper-wrapper {
  padding-inline-start: 0;
}
.coach-mark-swiper-popup .swiper-container > .swiper-wrapper > .swiper-slide {
  transition: margin-top 0.6s ease 0s;
  list-style: none;
  margin: 0;
  text-align: center;
  display: flex;
}
.coach-mark-swiper-popup .swiper-container > .swiper-wrapper > .swiper-slide > .coach-mark-slide {
  top: 0;
}
.coach-mark-swiper-popup .swiper-container > .swiper-wrapper > .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.coach-mark-swiper-popup .swiper-container > .swiper-scrollbar > .swiper-scrollbar-drag {
  background: rgba(255, 255, 255, 0.5);
}
