@charset "UTF-8";
/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
} */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.app-content {
		background-blend-mode: multiply;
	}
	.app-content > .login-container .link-btns-wrapper{
		font-size: 18px;
	}
	#home {
		position: relative;
		max-width: 500px;
		margin: 0 auto;
		height: 100%;
		background: no-repeat center / cover url('../images/background/home_bg.png');
	}
	#home .swiper-container .swiper-slide .img-wrapper.on {
		width: 400px;
	}
	#home .today-korean .today-korean-list-wrapper {
		width: 400px;
	}
	#home .swiper-container .swiper-slide .part-container {
		width: 400px;
	}
	.coach-mark-swiper-popup {
		width: 500px !important;
	}
	#learning-contents {
		max-width: 500px;
		margin: 0 auto;
	}
	.study-result-container .swiper-container > .swiper-button-prev,
	.study-result-container .swiper-container > .swiper-button-next {
		visibility: visible !important;
	}
	.learning-contents-container > .learning-bg {
		object-fit: contain !important;
	}
	#report-content {
		max-width: 500px;
		margin: 0 auto;
		background: no-repeat center / cover url('../images/background/home_bg.png');
	}
	#setting-contents {
		max-width: 500px;
		margin: 0 auto;
	}
	#bottom-tab {
		display: flex;
		justify-content: center;
	}
	#bottom-tab > .am-tab-bar {
		width: 500px;
	}
	.signup-container {
		max-width: 500px;
	}
	.signup-container .signup-btn-bundle {
		width: 500px !important;
	}
	.am-tab-bar-bar {
		border: 0 !important;
		/* border-top-right-radius: 10px;
		border-top-left-radius: 10px; */
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	/* #home {
		max-width: 350px;
		margin: 0 auto;
	}
	#bottom-tab {
		width: 350px;
		left: 50%;
		transform: translateX(-50%);
	} */
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;