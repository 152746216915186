.login-container {
    position: relative;
    max-width: 500px; height: 100%;
    margin: 0 auto;
    text-align: center;
    background-size: cover;
    z-index: 101;
    .main-title{
        margin-top: 25%;
    }
    .link-btns-wrapper{
        width: 80%;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30%;
        font-size: 15px;
        .link-btn{
            width: 100%; height: 2.5em;
            margin-bottom: 15px;
            background-color: rgba(21,23,25,0.7);
            border: 1px solid rgba(103,113,116,1);
            border-radius: calc(2.5em / 2);
            color: #fff;
        }
    }
}

.login-form-button {
    width: 100%;
}

.loading-indicator {
    width: 100px;
    height: 100px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
    & > img {
        width: 100%;
        height: 100%;
    }
}
@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;