@import url('@common/Popup');
@header-height: 50px;

.app-title {
	float: right;
}

.app-title a {
	text-decoration: none;
	line-height: 64px;
	font-size: 1.3rem;
	display: inline-block;
}

.app-title a:hover {
	text-decoration: none;
}

.app-header {
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: @header-height;
	width: 100%;
	z-index: 101;
	padding: 0;
	.header-item {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		width: 50px;
    &.back-btn{
      cursor: pointer;
    }
    & > #star{
      margin-right:10px;
      position: relative;
    }
    & > .star-anim {
      -webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
      animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
      // animation-delay:3s;
      animation-iteration-count: 1;
    }
    & > .numbering-anim{
      -webkit-animation: flip-2-hor-top-1 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
      animation: flip-2-hor-top-1 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
      //animation-delay:3.5s;
      animation-iteration-count: 1;
    }
    & > p#badge-num {
      position: absolute;
      line-height: initial;
      right: 5px;
      bottom: 10px;
      color: #fff;
      font-size: 0.6rem;
      white-space: nowrap;
    }
	}


  @-webkit-keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes flip-2-hor-top-1 {
    0% {
      -webkit-transform: translateY(0) rotateX(0);
              transform: translateY(0) rotateX(0);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(-360deg);
              transform: translateY(0) rotateX(-360deg);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @keyframes flip-2-hor-top-1 {
    0% {
      -webkit-transform: translateY(0) rotateX(0);
              transform: translateY(0) rotateX(0);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(-360deg);
              transform: translateY(0) rotateX(-360deg);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  
}
.popup();
.popup-background {
	animation: fadein 0.4s;
}
.popup {
	& > .popup-name {
		height: 70%;
		padding: 10px;
		font: 1.56rem 'NotoSans', sans-serif;
		font-weight: bold;
		overflow: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	& > .button-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 80px;
		& > button {
			position: relative;
			height: 32px;
			width: 80px;
			background: rgba(20, 23, 25, 0.9);
			font-family: 'NotoSans', sans-serif;
			border: 1.5px solid rgba(103, 113, 116, 0.3);
			margin: 0 10px;
			border-radius: 21px;
			&:active {
				background: rgba(0, 0, 0, 0.1);
			}
		}
	}
}

@media (max-width: 768px) {
	.app-title a {
		font-size: 1.25rem;
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;