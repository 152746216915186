// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
// 	0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
@import '@common/RecordingDots';
@import '@common/Popup';
@header-height: 50px;

.learning-contents-container {
	display: flex;
	flex-direction: column;
	z-index: 1;
	width: 100%;
	height: 100%;
	transition-duration: 0.6s;
	& > .learning-bg {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		z-index: 0;
		overflow: hidden;
		& > img {
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
			filter: blur(2px);
			color: #fff;
		}
	}
	.learning-header {
		position: relative;
		height: @header-height;
		text-align: center;
		line-height: @header-height;
		font-weight: bold;
		background: rgba(0, 0, 0, 0.3);
		color: #fff;
		text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
		z-index: 5;
	}
	#learning-contents {
		position: relative;
		height: 100%;
		width: 100%;
		// height: calc(100% - 50px);
		z-index: 1;
		overflow: hidden;
		& > .inner-bg {
			position: absolute;
			height: 100%;
			width: 100%;
			left: 50%;
			transform: translateX(-50%);
			top: 0;
			z-index: -1;
			object-fit: cover;
			overflow: hidden;
			color: #fff;
			& > img {
				height: 100%;
			}
		}
	}
	.button-widget-box {
		position: relative;
		.grammar-next-btn {
			display: block;
			right: 0px;
			margin-left: auto;
		}
	}
	.bottom {
		position: relative;
		bottom: 10%;
		left: 0;
		margin: 0 auto;
		position: fixed;
		display: block;
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 15px;
		.sound-widget-bundle {
			display: flex;
			justify-content: center;
			padding: 15px;
			.lesson1-study-widget {
				width: 65px;
				height: 65px;
				margin: 0 15px;
			}
		}
		.widget {
			bottom: 0px;
			animation: fadein 0.3s;
			cursor: pointer;
		}
		.recording-dots();
		.recording-dots-container {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
		}
	}

	.loading-indicator {
		width: 100px;
		height: 100px;
		// width: fit-content;
		// height: fit-content;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 100;
		& > img {
			width: 100%;
			height: 100%;
		}
	}
	.popup();
	.popup-background {
		animation: fadein 0.4s;
	}

	// .loading-indicator{

	// }

	.dimed-bg {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 100;

		display: flex;
		justify-content: center;
		background-color: rgba(21, 25, 29, 0.7);
		&.fade-anim {
			animation: fadein 0.6s; //, fadeout 2s 2.4s;
		}
		&.fadein {
			animation: fadein 0.6s;
		}
	}
	.btn-bundle {
		position: fixed;
		display: flex;
		align-items: center;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 101;
		.voca-widget {
			width: 52px;
			height: 52px;
			margin: 5px;
			border: 0;
			align-items: center;
			cursor: pointer;
		}
	}
	.absolute-center {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 100;
	}
	.retry-panel {
		position: absolute;
		top: 0;
		bottom: 250px;
		width: 90%;
		height: 70px;
		line-height: 70px;
		text-align: center;
		color: #fff;
		background-color: rgba(21, 25, 29, 0.9);
		border: 2px solid rgba(103, 113, 116, 0.3);
		border-radius: 10px;
		font-size: 1.1rem;
		font-weight: bold;
	}
	.badge {
		width: 150px;
		height: 150px;
		line-height: 150px;
		text-align: center;
		color: #fff;
		font-size: 3.1rem;
		font-weight: bold;
		box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);
		border-radius: 50%;
		animation: fadein 0.6s; //, fadeout 1s 1.2s;
	}
	.recording-popup {
		position: absolute;
		width: 100px;
		height: 45px;
		line-height: 45px;
		text-align: center;
		top: 70px;
		left: 0;
		right: 0;
		margin: auto;
		background: #fff;
	}
	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	@keyframes fadeout {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;