.daily-result-container {
	padding: 5%;
	width: 100%;
	height: 100%;
	.header .header-title {
		text-align: center;
		color: #fff;
		font-size: 1rem;
		margin-bottom: 15%;
	}
	.content {
		.calendar-wrapper {
			border-radius: 15px;
			.calendar-header {
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				min-width: 50%;
				& > .calendar-btn {
					background: none;
					border: none;
					margin: 2px;
					&.right {
						transform: scaleX(-1);
					}
				}
			}
			& > .ant-picker-calendar {
				border-radius: 20px;
				& > .ant-picker-panel {
					border-top: 0;
					.ant-picker-content {
						// 요일 컬러
						& > thead > tr > th {
							color: rgba(255, 255, 255, 0.5);
						}
						.ant-picker-cell {
							color: rgba(255, 255, 255, 0.5);
							&-in-view {
								color: #fff;
							}
							&-today {
								// border-bottom: 2px solid #fff;
							}
							& > .o {
								position: absolute;
								left: 50%;
								top: 50%;
								transform: translate(-50%, -50%);
								display: inline-block;
								line-height: 34px;
								width: 34px;
								height: 34px;
							}
							& > div {
								display: inline-block;
							}
						}
					}
				}
			}
		}
		.month-grid {
			width: 100%;
			min-width: 280px;
			margin: 0 auto;
			.am-flexbox {
				// width: 350px;
				// height: 50px;
				.am-grid-item-content {
					padding: 0;
					.grid-item {
						// width: 50px;
						// height: 50px;
						display: flex;
						justify-content: center;
						align-items: center;
						img {
							width: 90%;
							height: 100%;
							display: block;
							margin-top: 5px;
						}
					}
				}
			}
		}
		.result-container {
			position: relative;
			display: flex;
			width: 100%;
			height: 120px;
			margin: 30px 0;
			background-color: rgba(0, 0, 0, 0.5);
			border-radius: 12px;
			align-items: center;
			box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
			color: #fff;
			.daily-result {
				width: 100%;
				margin: 0 6%;
				.result-box {
					width: 100%;
					height: 30px;
					padding: 0 10px;
					margin-bottom: 3px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					div {
						flex: 0 0 auto;
					}
				}
			}
		}
	}
	.footer {
		display: flex;
		justify-content: center;
		// .btn-frame();
		.home-btn {
			background-color: #adb9ca;
			border-radius: 23px;
			height: 46px;
			width: 180px;
			border: none;
			color: #fff;
			font-size: 1.25rem;
			font-weight: 500;
		}
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;