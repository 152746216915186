.mixin-profile-header() {
	& > .profile-header {
		position: fixed;
		width: 100%;
		height: 50px;
		padding: 0 3%;
		z-index: 101;
		background-color: transparent;
		.button {
			width: auto;
			height: 50px;
			width: 50px;
			background-color: transparent;
			cursor: pointer;
			animation: fadein 0.6s;
		}
		.back-btn {
			border: 0;
			float: left;
		}
    .level-title {
      position: absolute;
      top: 0; 
      left: 50%; transform: translateX(-50%);
      padding: 12px 0;
      width: 200px;
      height: 50px;
      text-align: center;
      color: rgba(255, 255, 255, );
      font-size: 0.9rem;
      line-height: 28px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; // 글자가 너비를 넘을 경우 생략부호 표시
    }
		.setting-btn {
			border: 0;
			float: right;
		}
    
	}
}
