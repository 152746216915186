@import './ProfileHeader';

.profile-container {
	height: 100%;
	display: flex;
	overflow-x: hidden;
	padding-bottom: 50px;

	.mixin-profile-header();

	& > #report-content.swiper-box {
		overflow: auto;
		padding-top: 50px;
		width: 100%;
		height: 100%;
	}
	.swiper-container {
		width: 100%;
		height: 100%;
		.swiper-wrapper {
			padding: 0;
			.swiper-slide {
				list-style: none;
				&:last-child {
					overflow: visible;
				}
			}
		}
	}
	.swiper-slide.report-wrapper {
		height: 100%;
		width: 100%;
		z-index: 10;
		overflow: auto;

		.profile-wrapper {
			padding: 0 5%;
		}
		.profile {
			display: flex;
			height: 120px;
			padding: 10px 0;
			background-color: rgba(0, 0, 0, 0.6);
			color: #fff;
			border-radius: 15px;
			border: 1.5px solid rgba(103, 113, 116, 0.3);
			margin: 20px 0px;
			overflow: hidden;
			.user-avatar {
				flex: 0 0 1px;
				width: 100px;
				height: 100%;
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				text-align: center;
				.avatar {
					background-color: rgba(255, 255, 255);
					border: 3px rgba(143, 143, 143) solid;
					width: 66px;
					height: 66px;
					line-height: 50px;
					border-radius: 50%;
					& > .ant-avatar-string {
						color: rgba(106, 121, 247, 1);
						top: 10%;
						font-size: 1.375rem;
					}
				}
				.user-name {
					width: inherit;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					font-size: 0.7rem;
					font-weight: bold;
					padding-left: 3px;
				}
			}
			.user-info {
				width: 100%;
				padding: 20px 10px;
				.info {
					font-size: 1rem;
					display: flex;
					justify-content: space-between;
					&:first-child {
						margin-bottom: 10px;
					}
					& > p {
						display: inline-block;
					}
					& > p:first-child {
						flex: 0 0 auto;
					}
				}
			}
		}
		.report-panel {
			background-color: rgba(0, 0, 0, 0.6);
			border: 1px solid rgba(103, 113, 116, 0.3);
			border-radius: 15px;
			cursor: pointer;
		}
		/* 학습 현황 */
		.learning-state {
			width: 100%;
			height: 100%;
			.level-btn {
				position: relative;
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				padding: 10px;
				margin-bottom: 10px;
				.title-box {
					width: 80px;
					.title {
						color: rgb(255, 255, 255);
						font-size: 25px;
						font-weight: 600;
					}
				}
				.score-box {
					margin-right: 100px;
					font-size: 25px;
					color: #08c1bb;
				}
				.badge-box {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 0.75rem;
					.ant-progress-inner {
						background-repeat: no-repeat;
						margin: 0;
						padding: 0;
						width: 50px;
						height: 50px;
					}
					.ant-progress-circle-path {
						stroke-linecap: square;
					}
					.ant-progress-circle-trail {
						stroke: #d1d1d1;
					}
					.ant-progress-text {
						display: none;
						font-size: 1em;
					}
					& > img {
						margin: 0;
						padding: 0;
						width: 50px;
						height: 50px;
					}
					& > .progress-num {
						margin-top: 5px;
						color: rgba(255, 255, 255);
						font-size: 0.75rem;
					}
				}
			}
		}
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;