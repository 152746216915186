.popup() {
	.popup-background {
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(21,25,29, 0.7);
		left: 0;
		top: 0;
		z-index: 102;
		&.on {
		}
		.popup {
			position: absolute;
			overflow: hidden;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 342px;
			height: 216px;
			margin: auto;
			background: rgba(20,23,25,0.9);
			border-radius: 20px;
      border: 1.5px solid rgba(103, 113, 116, 0.3);
			color: rgba(239,239,239);
		}
	}
}
