@import url('@common/Popup');

.setting-container {
	.setting {
		position: fixed;
		display: grid;
		grid-auto-rows: minmax(80%, auto);
		top: 0;
		right: 0;
		bottom: 50px;
		left: 0;
		width: 100%;
		z-index: 100;
		padding-top: 50px;
		& > .top {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		& > .bottom {
			display: flex;
			justify-content: center;
		}
		.setting-btn {
			position: relative;
			width: 90%;
			height: 60px;
			min-height: 60px;
			line-height: 60px;
			color: rgba(255, 255, 255);
			font-size: 0.9rem;
			font-weight: 700;
			padding-left: 40px;
			background-color: rgba(20, 23, 25, 0.7);
			border-radius: 15px;
			text-align: left;
			border: 1.5px solid rgba(103, 113, 116, 0.3);
			margin-bottom: 5%;
			cursor: pointer;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.popup();
	.popup {
		& > .popup-name {
			height: 70%;
			padding: 10px;
			font: 1.56rem 'NotoSans', sans-serif;
			font-weight: bold;
			overflow: auto;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		& > .button-wrap {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px 80px;
			& > button {
				position: relative;
				height: 32px;
				width: 80px;
				background: rgba(20, 23, 25, 0.9);
				font-family: 'NotoSans', sans-serif;
				border: 1.5px solid rgba(103, 113, 116, 0.3);
				margin: 0 10px;
				border-radius: 21px;
				&:active {
					background: rgba(0, 0, 0, 0.1);
				}
			}
		}
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;