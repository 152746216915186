// 유형(Step) 패널 색
@lesson-bg-color: rgba(32, 34, 36, 0.58); // #202224
@lesson-border-color: rgba(103, 113, 116, 0.3); // #677174

// 일반 배경 색
@common-bg-color: rgba(20, 23, 25, 0.7);
@common-border-color: rgba(103, 113, 116, 0.3); // #677174 두께 1

// 일반 딤 처리 색
@common-dimed-bg: rgba(21, 25, 29, 0.7); // #15191D

@common-theme-color: rgb(8, 193, 187); // #08C1BB;
@common-badge-color: rgb(56, 196, 172); // #38C4AC;
@common-badge-border-color: rgb(53, 207, 183); //#35CFB7;

// change antd selet compo style
.ant-select-dropdown {
	background-color: rgba(20, 23, 25, 0.7);
}
.rc-virtual-list {
	.ant-select-item-option {
		&-active {
			background-color: rgba(103, 113, 116, 0.3) !important;
		}
		&-selected {
			background-color: rgba(103, 113, 116, 0.3) !important;
		}
	}
}

// common style
.normal-bg {
	background-color: rgba(20, 23, 25, 0.7);
	border: 1px solid rgba(103, 113, 116, 0.3);
	color: rgb(255, 255, 255);
}

.btn-frame {
	position: relative;
	display: block;
	border-radius: 25px;
	height: 50px;
	margin: 0 5px;
	padding: 0 20px;
	background-color: rgb(8, 193, 187);
	border: 3px solid rgb(171, 241, 230);
	color: #fff;
	font-size: 1.25rem;
	font-weight: bold;
	white-space: nowrap;
	cursor: pointer;
	& > img {
		margin: 0 5px;
		transform: scaleX(-1);
		vertical-align: baseline;
	}
	&:last-child {
		margin-right: 0px;
	}
}

.pop-btn-frame:active {
	position: relative;
	display: block;
	border-radius: 25px;
	width: 215px;
	height: 47px;
	margin: 0 5px;
	padding: 0 20px;
	background-color: rgb(8, 193, 187);
	border: 3px solid rgb(171, 241, 230);
	color: #fff;
	font-size: 1.25rem;
	font-weight: bold;
	white-space: nowrap;
	& > img {
		margin: 0 5px;
		transform: scaleX(-1);
		vertical-align: baseline;
	}
	&:last-child {
		margin-right: 0px;
	}
}

.pop-btn-frame {
	position: relative;
	display: block;
	border-radius: 25px;
	width: 215px;
	height: 47px;
	margin: 0 5px;
	padding: 0 20px;
	background-color: rgb(117, 129, 139);
	border: 3px solid rgb(181, 194, 202);
	color: #fff;
	font-size: 1.25rem;
	font-weight: bold;
	white-space: nowrap;
	& > img {
		margin: 0 5px;
		transform: scaleX(-1);
		vertical-align: baseline;
	}
	&:last-child {
		margin-right: 0px;
	}
}

.bg-center {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
}

.common-scroll {
	&-white {
		&::-webkit-scrollbar-thumb {
			background: rgba(255, 255, 255, 0.25);
			border-radius: 10px;
		}
		&::-webkit-scrollbar {
			width: 7px;
		}
		&::-webkit-scrollbar-track {
			background-color: transparent;
		}
	}
	&-black {
		&::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0.25);
			border-radius: 10px;
		}
		&::-webkit-scrollbar {
			width: 7px;
		}
		&::-webkit-scrollbar-track {
			background-color: transparent;
		}
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@layout-body-background: transparent;@layout-header-background: transparent;@layout-footer-background: transparent;@calendar-bg: rgba(20, 23, 25, 0.7);@calendar-border-color: rgba(103, 113, 116, 0.3);@calendar-item-active-bg: rgb(56, 196, 172);@calendar-full-bg: @calendar-bg;@calendar-full-panel-bg: transparent;